<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  fileLike: {
    type: [String, Object],
    required: true
  }
})

const fileNameRegExp = new RegExp(/[^/\\?]+\.\w{3,4}(?=([?&].*$|$))/)
const fullName = computed(() => {
  if (typeof props.fileLike === 'string') {
    return fileNameRegExp.exec(props.fileLike)[0]
  }
  const nameStr = props.fileLike.fileName || props.fileLike.name
  if (nameStr) return nameStr
  const urlStr = props.fileLike.fileUrl || props.fileLike.url
  return fileNameRegExp.exec(urlStr)[0]
})
const name = computed(() => {
  return fullName.value.substring(0, fullName.value.lastIndexOf('.'))
})
const type = computed(() => {
  return fullName.value.substring(fullName.value.lastIndexOf('.'))
})
</script>

<template>
  <div class="link-container" @click="$previewFile(fileLike)">
    <span class="filename-link" :title="name+type" slot="reference">
      <span class="name">{{ name }}</span><span class="ext">{{ type }}</span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.link-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.filename-link {
  max-width: 100%;
  color: var(--color-primary);
  cursor: pointer;
  * {
    display: inline-block;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ext {
  min-width: 2em;
}
</style>
