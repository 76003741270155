<template>
  <div class="content_bottom">
    <div class="content_box">
      <div class="start_flex mb_30">
        <p class="mr_30">友情链接：</p>
        <p class="mr_40 hand" @click="toLink('https://www.reht.com/')">中国北方稀土（集团）高科技股份有限公司</p>
        <p class="mr_40 hand" @click="toLink('https://www.repe.com.cn/')">包头产品稀土交易所</p>
        <p class="hand" @click="toLink('https://www.ac-rei.org.cn/')">中国稀土行业协会</p>
      </div>
      <div class="start_flex">
        <p class="mr_60">承建机构：蒙西供应链管理有限公司</p>
        <p style="margin-right: 52px" class="hand"><a href="https://beian.miit.gov.cn" target="_blank">备案序号：蒙ICP备2022003545号</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'PageBottom',
  methods:{
    toLink(path) {
      window.open(path);
    }
  }
};
</script>

<style scoped>
.mr_40 {
  margin-right: 40px;
}
.mr_30 {
  margin-right: 20px;
}
.mr_60 {
  margin-right: 60px;
}
.content_bottom {
  width: 100%;
  background-color: #262a36;
}
.line {
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: .2;
  position: relative;
  top: 65px;
}
.content_bottom * {
  box-sizing: border-box;
}
.content_bottom .content_box {
  background-color: #262a36;
  min-width: 768px;
  margin: 0 auto;
  height: 120px;
  padding-top: 35px;
}
.mb_30 {
  margin-bottom: 25px;
}
.start_flex {
  display: flex;
  justify-content: center;
}
.start_flex p {
  height: 16px;
  line-height: 10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  opacity: 0.71;
  text-decoration: none;
}
.start_flex a  {
  text-decoration: none;
  color: #fff;
}
.top {
  display: flex;
  height: 40px;
  justify-content: center;
}
.top p {
  font-size: 16px;
  color: #fff;
}
.yl {
  width: 80%;
  margin: 20px auto;
}
.yl ul {
  display: flex;
  justify-content: space-between;
}
.yl ul li > a {
  height: 16px;
  line-height: 15px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  opacity: 0.71;
  text-decoration: none;

}
</style>