import router from "@/router";
import axios from "axios";
import { Message } from "element-ui";
import { postLogoutMessage } from '@/utils/logout'
console.log(process.env.VUE_APP_API_URL);

// create an axios instance
const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // api 的 base_url
  timeout: 60000, // request timeout
});

export const loginRequestMiddleware = config => {
  if ( // 登录相关的接口全部不处理请求头逻辑
    config.url.includes('accountLogin') // 账号密码登录接口
    || config.url.includes('permission/getUserInfo') // session登录接口
  ) {
    return config
  }
  // 在此处添加你的请求头
  let sessid = "";
  if (sessionStorage.getItem("userinfo")) {
    const { loginTime, sessionId, identity } = JSON.parse(sessionStorage.getItem("userinfo"));
    const hoursLogged = (Date.now() - new Date(loginTime))/1000/60/60;
    if (hoursLogged >= 12) {
      Message.error('登录已过期，请重新登录.');
      sessionStorage.clear();
      postLogoutMessage(identity, true)
      router.push({
        path: "/login",
        query: { flag: false }
      });
    }
    sessid = sessionId;
  }
  config.headers["rareEarthSessionId"] = sessid;
  return config;
}

export const errorResponseMiddleware = error => {
  let uploadFail = JSON.parse(sessionStorage.getItem("uploadFail"));
  if (uploadFail) {
    error["message"] = "上传失败，网络超时";
    Message.error(error["message"]);
    return Promise.reject(error);
  } else {
    sessionStorage.setItem("uploadFail", false);
    console.error(error);
    if (String(error.response.status).includes('5')) {
      Message.error(`服务器内部错误：${error.response.data.message}`)
    } else {
      if (error.message == "Network Error") {
        error["message"] = "系统异常" + error.message;
      } else if (error.message.indexOf("timeout") != -1) {
        error["message"] = "返回超时";
      }
      Message.error(error.response.data.message);
    }
    return Promise.reject(error);
  }
}

request.interceptors.request.use(
  loginRequestMiddleware,
  error => {
    return Promise.reject(error);
  }
);

//响应返回时拦截
request.interceptors.response.use(
  response => {
    const res = response.data;
    if (Number(res.code) == 401) {
      localStorage.clear();
      sessionStorage.clear();
      Message.error("企业已停用");
      setTimeout("location.href='/login'", 100);
      return res;
    }
    return res;
  },
  errorResponseMiddleware
);

export default request;
