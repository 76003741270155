/*
 * @Author: 祝瑶瑶 zhuyaoyao@youpinsanyue.com
 * @Date: 2022-09-26 11:09:02
 * @LastEditors: 祝瑶瑶 zhuyaoyao@youpinsanyue.com
 * @LastEditTime: 2022-09-29 09:03:44
 * @FilePath: \rare-admin\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Router from "vue-router";
import Index from "@/pages/index";
// {
//   hidden: false,   //false 展示在侧边栏
//   path: "procurementList",
//   component: () => import("@/pages/procurement/purchase.vue"),
//   name: "procurementList",
//   id: 154,           根据登录的权限 更改 hidden   判断是否在侧边栏
//   meta: {
//     title: "采购订单",
//     icon: "menu",
//     showTag:true    非侧边 在加载路由的时候 不会在tag列表上   当showTag==true 的时候会展示在tag列表上
//   }
// },
Vue.use(Router);

// admin平台端
export const qiyeNavRouter = [
  {
    hidden: false,
    path: "/customerManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "Enterprise",
    id: 1,
    meta: {
      title: "客户管理",
      icon: "enterprise"
    },
    redirect: "/customerManagement/enterpriseInfo",
    children: [
      {
        hidden: false,
        id: 200,
        path: "enterpriseInfo",
        component: () =>
          import("@/pages/customerManagement/enterpriseInfo.vue"),
        name: "EnterpriseInfo",
        meta: {
          title: "企业信息",
          icon: "menu"
        }
      },
      {
        hidden: true,
        path: "enterpriseDetail",
        component: () =>
          import("@/pages/customerManagement/enterpriseDetail.vue"),
        name: "EnterpriseDetail",
        meta: {
          title: "企业信息详情",
          icon: "menu"
        }
      },
      {
        hidden: false,
        path: "accountInfo",
        component: () => import("@/pages/customerManagement/accountInfo.vue"),
        name: "AccountInfo",
        id: 206,
        meta: {
          title: "账户信息",
          icon: "menu"
        }
      },
      {
        hidden: true,
        path: "accountDetail",
        component: () => import("@/pages/customerManagement/accountDetail.vue"),
        name: "AccountDetail",
        meta: {
          title: "账户信息详情",
          icon: "menu"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/product",
    component: () => import("@/pages/app_main.vue"),
    name: "product",
    id: 76,
    meta: {
      title: "产品管理",
      icon: "supplier"
    },
    redirect: "/product/list",
    children: [
      {
        hidden: false,
        path: "list",
        component: () => import("@/pages/product/list.vue"),
        name: "productList",
        id: 77,
        meta: {
          title: "产品信息",
          icon: "menu"
        }
      }
    ]
  },
   // 合同管理
   {
    hidden: false,
    path: "/contractManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "ContractManagement",
    redirect: "/contractManagement/contractManagementList",
    id: 100,
    meta: {
      title: "合同管理",
      icon: "constract"
    },
    children: [
      {
        hidden: false,
        path: "contractManagementList",
        component: () => import("@/pages/contractManagement/list.vue"),
        name: "contractManagementList",
        id: 101,
        meta: {
          title: "长协协议",
          icon: "constract"
        }
      },
      {
        hidden: true,
        path: "contractManagementDetail",
        component: () => import("@/pages/contractManagement/detail.vue"),
        name: "contractManagementDetail",
        meta: {
          title: "查看协议",
          icon: "constract"
        }
      },
      {
        hidden: false,
        path: "contractList",
        component: () => import("@/pages/contractManagement/contractList.vue"),
        name: "contractList",
        id: 102,
        meta: {
          title: "购销合同",
          icon: "constract"
        }
      },
      {
        hidden: true,
        path: "contracts/:contractId",
        component: () => import("@/pages/contractManagement/contractDetail.vue"),
        props: ({ params, meta }) => ({
          pageTitle: meta.title,
          contractId: params.contractId
        }),
        name: "view-contract",
        meta: {
          title: "查看合同",
          icon: "constract"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/order",
    component: () => import("@/pages/app_main.vue"),
    name: "order",
    id: 201,
    meta: {
      title: "订单管理",
      icon: "supplier"
    },
    redirect: "/order/list",
    children: [
      {
        hidden: false,
        path: "list",
        component: () => import("@/pages/orderManagement/adminList.vue"),
        name: "orderList",
        id: 202,
        meta: {
          title: "订单信息",
          icon: "menu"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/documentManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "documentManagement",
    redirect: "/documentManagement/documentManagementList",
    id: 190,
    meta: {
      title: "单据管理",
      icon: "supplier"
    },
    children: [
      {
        hidden: false,
        path: "documentManagementList",
        component: () => import("@/pages/documentManagement/list.vue"),
        name: "documentManagementList",
        id: 191,
        meta: {
          title: "单据信息",
          icon: "supplier"
        }
      },
      {
        hidden: true,
        path: "documentManagementDetail",
        component: () => import("@/pages/documentManagement/detail.vue"),
        name: "documentManagementDetail",
        meta: {
          title: "查看详情",
          icon: "menu"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/protocolReview",
    component: () => import("@/pages/app_main.vue"),
    name: "ProtocolReview",
    redirect: "/protocolReview/protocolReviewList",
    id: 400,
    meta: {
      title: "授权协议",
      icon: "supplier"
    },
    children: [
      {
        hidden: false,
        path: "protocolReviewList",
        component: () => import("@/pages/protocolReview/list.vue"),
        name: "protocolReviewList",
        id: 401,
        meta: {
          title: "协议审核",
          icon: "supplier"
        }
      },
      {
        hidden: true,
        path: "protocolReviewDetail",
        component: () => import("@/pages/protocolReview/detail.vue"),
        name: "protocolReviewDetail",
        meta: {
          title: "查看详情",
          icon: "menu"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/news",
    component: () => import("@/pages/app_main.vue"),
    name: "News",
    id: 90,
    meta: {
      title: "资讯管理",
      icon: "news"
    },
    redirect: "/news/list",
    children: [
      {
        hidden: false,
        path: "list",
        id: 91,
        component: () => import("@/pages/news/NewsList.vue"),
        name: "NewsList",
        meta: {
          title: "资讯列表",
          icon: "news"
        }
      },
      {
        hidden: true,
        path: "detail",
        component: () => import("@/pages/news/detail.vue"),
        name: "NewsDetail",
        meta: {
          title: "资讯详情",
          icon: "news"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/quotation",
    component: () => import("@/pages/app_main.vue"),
    name: "quotation",
    id: 97,
    meta: {
      title: "稀土行情",
      icon: "xitu"
    },
    redirect: "/quotation/date",
    children: [
      {
        hidden: false,
        path: "date",
        id: 98,
        component: () => import("@/pages/quotation/index.vue"),
        name: "quotationDate",
        meta: {
          title: "稀土价格",
          icon: "xitu"
        }
      },
      {
        hidden: true,
        path: "detail",
        component: () => import("@/pages/quotation/detail.vue"),
        name: "quotationDetail",
        meta: {
          title: "稀土详情",
          icon: "news"
        }
      }
    ]
  },
  {
    hidden: false,
    path: "/account",
    component: () => import("@/pages/app_main.vue"),
    name: "A6",
    id: 27,
    meta: {
      title: "账号管理",
      icon: "account"
    },
    children: [
      {
        hidden: false,
        path: "role",
        component: () => import("@/pages/account/role/list.vue"),
        name: "role",
        id: 28,
        meta: {
          title: "角色管理",
          icon: "menu"
        }
      },
      {
        hidden: true,
        path: "persetting",
        component: () => import("@/pages/account/role/persetting"),
        name: "PerSetting",
        meta: {
          title: "设置权限",
          icon: "menu"
        }
      },
      {
        hidden: false,
        path: "accountList",
        component: () => import("@/pages/account/accountMng/index"),
        name: "Account",
        id: 34,
        meta: {
          title: "账号管理",
          icon: "menu"
        }
      }
    ]
  }
];
// 企业端
export const NavRouter = [
  // 首页
  {
    hidden: false,
    path: "/home",
    component: () => import("@/pages/app_main.vue"),
    name: "HomeIndex",
    redirect: "/home/info",
    id: 300,
    meta: {
      title: "首页",
      icon: "home"
    },
    children: [
      {
        hidden: false,
        path: "info",
        component: () => import("@/pages/homePage/index.vue"),
        name: "HomeInfo",
        id: 301,
        meta: {
          title: "首页",
          icon: "supplier"
        }
      },
      {
        hidden: true,
        path: "enterpriseCertification",
        component: () => import("@/pages/homePage/enterpriseCertification.vue"),
        name: "EnterpriseCertification",
        meta: {
          title: "企业认证",
          icon: "menu"
        }
      }
    ]
  },
  // 企业管理-企业信息
  {
    hidden: false,
    path: "/EnterpriseIndex",
    component: () => import("@/pages/app_main.vue"),
    name: "EnterpriseIndex",
    redirect: "/EnterpriseIndex/enterpriseInformation",
    id: 302,
    meta: {
      title: "企业管理",
      icon: "enterprise"
    },
    children: [
      {
        id: 303,
        hidden: false,
        path: "enterpriseInformation",
        component: () => import("@/pages/enterpriseInformation/index.vue"),
        name: "EnterpriseInformation",
        meta: {
          title: "企业信息",
          icon: "menu"
        }
      },
      {
        hidden: true,
        path: "changeInformation",
        component: () =>
          import("@/pages/enterpriseInformation/changeInformation.vue"),
        name: "changeInformation",
        meta: {
          title: "变更信息",
          icon: "menu"
        }
      }
    ]
  },
  // 客户管理
  {
    hidden: false,
    path: "/customerManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "CustomerManagement",
    redirect: "/customerManagement/customerManagementList",
    id: 180,
    meta: {
      title: "客户管理",
      icon: "supplier"
    },
    children: [
      {
        hidden: false,
        path: "customerManagementList",
        component: () => import("@/pages/customerManagement/info.vue"),
        name: "customerManagementList",
        id: 181,
        meta: {
          title: "客户信息",
          icon: "supplier"
        }
      },
      {
        hidden: true,
        path: "customerManagementDetail",
        component: () => import("@/pages/customerManagement/detail.vue"),
        name: "customerManagementDetail",
        meta: {
          title: "添加客户",
          icon: "menu"
        }
      }
    ]
  },
  // 合同管理
  {
    hidden: false,
    path: "/contractManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "ContractManagement",
    redirect: "/contractManagement/contractManagementList",
    id: 183,
    meta: {
      title: "合同管理",
      icon: "constract"
    },
    children: [
      {
        hidden: false,
        path: "contractManagementList",
        component: () => import("@/pages/contractManagement/list.vue"),
        name: "contractManagementList",
        id: 184,
        meta: {
          title: "长协协议",
          icon: "constract"
        }
      },
      {
        hidden: true,
        path: "contractManagementDetail",
        component: () => import("@/pages/contractManagement/detail.vue"),
        name: "contractManagementDetail",
        meta: {
          title: "添加协议",
          icon: "constract"
        }
      },
      {
        hidden: false,
        path: "contractList",
        component: () => import("@/pages/contractManagement/contractList.vue"),
        name: "contractList",
        id: 185,
        meta: {
          title: "购销合同",
          icon: "constract"
        }
      },
      {
        hidden: true,
        path: "contracts/new",
        component: () => import("@/pages/contractManagement/contractDetail.vue"),
        props: ({ meta }) => ({
          isCreate: true,
          pageTitle: meta.title
        }),
        name: "create-contract",
        meta: {
          title: "添加合同",
          icon: "constract"
        }
      },
      {
        hidden: true,
        path: "contracts/:contractId",
        component: () => import("@/pages/contractManagement/contractDetail.vue"),
        props: ({ params, meta }) => ({
          pageTitle: meta.title,
          contractId: params.contractId
        }),
        name: "view-contract",
        meta: {
          title: "查看合同",
          icon: "constract"
        }
      },
      {
        hidden: true,
        path: "contracts/:contractId/edit",
        component: () =>
          import("@/pages/contractManagement/contractDetail.vue"),
        props: ({ params, meta }) => ({
          isEdit: true,
          pageTitle: meta.title,
          contractId: params.contractId
        }),
        name: "edit-contract",
        meta: {
          title: "编辑合同",
          icon: "constract"
        }
      }
    ]
  },
  // 销售管理
  {
    hidden: false,
    path: "/orderManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "SaleManagement",
    redirect: "/orderManagement/list",
    id: 186,
    meta: {
      title: "销售管理",
      icon: "manage"
    },
    children: [
      {
        hidden: false,
        path: "list",
        component: () => import("@/pages/orderManagement/list.vue"),
        name: "list-purchase-orders",
        id: 187,
        meta: {
          title: "销售订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "online-orders/new",
        component: () => import("@/pages/orderManagement/detail.vue"),
        props: { isCreate: true },
        name: "create-online-order",
        meta: {
          title: "创建线上确认订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "online-orders/:orderNo",
        component: () => import("@/pages/orderManagement/detail.vue"),
        props: ({ params, query }) => ({
          orderNo: params.orderNo,
          isView: true,
          isFromSupplyOrder: !!query.isSupplyOrder || query.type == 2,
          status: query.status
        }),
        name: "view-online-order",
        meta: {
          title: "线上确认订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "online-orders/:orderNo/edit",
        component: () => import("@/pages/orderManagement/detail.vue"),
        props: ({ params, query }) => ({
          orderNo: params.orderNo,
          isEdit: true,
          isFromSupplyOrder: !!query.isSupplyOrder || query.type == 2,
          status: query.status
        }),
        name: "edit-online-order",
        meta: {
          title: "编辑线上确认订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "online-orders/:orderNo/edit",
        component: () => import("@/pages/orderManagement/detail.vue"),
        props: ({ params, query }) => ({
          orderNo: params.orderNo,
          isConfirm: true,
          isFromSupplyOrder: !!query.isSupplyOrder || query.type == 2,
          status: query.status
        }),
        name: "confirm-online-order",
        meta: {
          title: "编辑线上确认订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "offline-orders/new",
        component: () => import("@/pages/orderManagement/offlineOrder.vue"),
        name: "create-offline-order",
        props: ({ query, meta }) => ({
          isCreate: true,
          pageTitle: meta.title,
          /** 从参数中获取订单类型，默认为销售订单 */
          orderType: query.orderType || 1,
          status: 0,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        meta: {
          title: "创建线下确认订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: 'offline-orders/:orderNo',
        props: ({ meta }) => ({
          pageTitle: meta.title
        }),
        component: () => import('@/pages/orderManagement/order.vue'),
        name: 'view-offline-order',
        meta: {
          title: '查看线下确认订单',
          icon: 'manage'
        }
      },
      {
        hidden: true,
        path: "offline-orders/:orderNo/edit",
        component: () => import("@/pages/orderManagement/offlineOrder.vue"),
        name: "edit-offline-order",
        props: ({ params, query, meta }) => ({
          isCreate: false,
          pageTitle: meta.title,
          orderNo: params.orderNo,
          /** 从参数中获取订单类型，默认为销售订单 */
          orderType: query.orderType || 1,
          status: query.status || 0,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        meta: {
          title: "编辑线下确认订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "offline-orders/next",
        component: () => import("@/pages/orderManagement/secondOrder.vue"),
        name: "create-offline-order-next",
        props: ({ query }) => ({
          pageTitle: '创建线下确认订单',
          status: query.status || 0,
          hasError: !!query.hasError,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        meta: {
          title: "创建线下确认订单第二步",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "offline-orders/:orderNo/next",
        component: () => import("@/pages/orderManagement/secondOrder.vue"),
        name: "edit-offline-order-next",
        props: ({ params, query }) => ({
          pageTitle: '编辑线下确认订单',
          orderNo: params.orderNo,
          status: query.status || 0,
          hasError: !!query.hasError,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        meta: {
          title: "编辑线下确认订单第二步",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "makeup-orders/new",
        component: () => import("@/pages/orderManagement/additionalRecording.vue"),
        props: ({ meta, query }) => ({
          isCreate: true,
          pageTitle: meta.title,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        name: "create-makeup-order",
        meta: {
          title: "补录订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "makeup-orders/:orderNo",
        component: () => import("@/pages/orderManagement/additionalRecording.vue"),
        props: ({ meta, params, query }) => ({
          isView: true,
          orderNo: params.orderNo,
          pageTitle: meta.title,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        name: "view-makeup-order",
        meta: {
          title: "查看补录订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "makeup-orders/:orderNo/edit",
        component: () => import("@/pages/orderManagement/additionalRecording.vue"),
        props: ({ meta, params, query }) => ({
          isEdit: true,
          orderNo: params.orderNo,
          pageTitle: meta.title,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        name: "edit-makeup-order",
        meta: {
          title: "编辑补录订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "makeup-orders/:orderNo/confirm",
        component: () => import("@/pages/orderManagement/additionalRecording.vue"),
        props: ({ meta, query }) => ({
          isConfirm: true,
          pageTitle: meta.title,
          isFromSupplyOrder: !!query.isFromSupplyOrder
        }),
        name: "confirm-makeup-order",
        meta: {
          title: "确认补录订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "goShip/:orderNo",
        component: () => import("@/pages/orderManagement/goShip.vue"),
        props: ({ params }) => ({ orderNo: params.orderNo }),
        name: "goShip",
        meta: {
          title: "去发货",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "goInvoice/:orderNo",
        component: () => import("@/pages/orderManagement/goInvoice.vue"),
        props: ({ params }) => ({ orderNo: params.orderNo }),
        name: "goInvoice",
        meta: {
          title: "去开票",
          icon: "manage"
        }
      }
    ]
  },
  // 采购管理
  {
    hidden: false,
    path: "/orderManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "BuyManagement",
    redirect: "/orderManagement/orderManagementPOList",
    id: 189,
    meta: {
      title: "采购管理",
      icon: "manage"
    },
    children: [
      {
        hidden: false,
        path: "orderManagementPOList",
        component: () => import("@/pages/orderManagement/poList.vue"),
        name: "orderManagementPOList",
        id: 188,
        meta: {
          title: "采购订单",
          icon: "manage"
        }
      },
      {
        hidden: true,
        path: "confirmationBilling/:orderNo",
        props: ({ params }) => ({ orderNo: params.orderNo }),
        component: () =>
          import("@/pages/orderManagement/confirmationBilling.vue"),
        name: "confirmationBilling",
        meta: {
          title: "确认开票",
          icon: "manage"
        }
      },
      {
        // 去验收 inspection
        hidden: true,
        path: "goInspection/:orderNo",
        component: () => import("@/pages/orderManagement/goInspection.vue"),
        props: ({ params }) => ({ orderNo: params.orderNo }),
        name: "goInspection",
        meta: {
          title: "去验收",
          icon: "manage"
        }
      }
    ]
  },
  // 单据管理
  {
    hidden: false,
    path: "/documentManagement",
    component: () => import("@/pages/app_main.vue"),
    name: "documentManagement",
    redirect: "/documentManagement/documentManagementList",
    id: 190,
    meta: {
      title: "单据管理",
      icon: "file"
    },
    children: [
      {
        hidden: false,
        path: "documentManagementList",
        component: () => import("@/pages/documentManagement/list.vue"),
        name: "documentManagementList",
        id: 191,
        meta: {
          title: "单据信息",
          icon: "file"
        }
      },
      {
        hidden: true,
        path: "documentManagementDetail",
        component: () => import("@/pages/documentManagement/detail.vue"),
        name: "documentManagementDetail",
        meta: {
          title: "查看详情",
          icon: "file"
        }
      }
    ]
  },
  // 发票管理
  {
    hidden: false,
    path: "/invoices",
    component: () => import("@/pages/app_main.vue"),
    name: "invoice-management",
    redirect: "/invoices/list",
    id: 192,
    meta: {
      title: "发票管理",
      icon: "file"
    },
    children: [
      {
        hidden: false,
        path: "list",
        component: () => import("@/pages/invoiceManagement/invoiceList.vue"),
        name: "invoice-list",
        id: 193,
        meta: {
          title: "发票信息",
          icon: "file"
        }
      },
      {
        hidden: true,
        path: "import",
        component: () => import("@/pages/invoiceManagement/importInvoices"),
        name: "import-invoice",
        meta: {
          title: "导入发票",
          icon: "file"
        }
      },
      {
        hidden: true,
        path: "import/:orderNo",
        component: () => import("@/pages/invoiceManagement/importInvoices"),
        props: ({ params }) => ({ orderNo: params.orderNo }),
        name: "import-invoice",
        meta: {
          title: "导入发票",
          icon: "file"
        }
      },
      {
        hidden: true,
        path: "add",
        component: () => import("@/pages/invoiceManagement/addInvoice.vue"),
        props: ({ params }) => ({ invoiceNo: params.invoiceNo, isAdd: true }),
        name: "add-invoice",
        meta: {
          title: "添加发票",
          icon: "file"
        }
      },
      {
        hidden: true,
        path: ":invoiceNo",
        component: () => import("@/pages/invoiceManagement/addInvoice.vue"),
        props: ({ params }) => ({ invoiceNo: params.invoiceNo }),
        name: "view-invoice",
        meta: {
          title: "查看发票",
          icon: "file"
        }
      },
      {
        hidden: true,
        path: ":invoiceNo/edit",
        component: () => import("@/pages/invoiceManagement/addInvoice.vue"),
        props: ({ params }) => ({ invoiceNo: params.invoiceNo, isEdit: true }),
        name: "edit-invoice",
        meta: {
          title: "编辑发票",
          icon: "file"
        }
      },
    ]
  },
  // 融资管理
  {
    hidden: false,
    path: "/finance",
    component: () => import("@/pages/app_main.vue"),
    name: "Finance",
    id: 109,
    meta: {
      title: "融资管理",
      icon: "financing"
    },
    children: [
      {
        hidden: false,
        path: "apply",
        component: () => import("@/pages/financing/apply/list.vue"),
        name: "FinanceApply",
        id: 151,
        meta: {
          title: "融资申请",
          icon: "menu"
        }
      }
    ]
  },
  // 账号管理
  {
    hidden: false,
    path: "/account",
    component: () => import("@/pages/app_main.vue"),
    name: "A6",
    id: 127,
    meta: {
      title: "账号管理",
      icon: "account"
    },
    children: [
      {
        hidden: false,
        path: "roleInfo",
        component: () => import("@/pages/account/role/info.vue"),
        name: "role",
        id: 128,
        meta: {
          title: "角色信息",
          icon: "menu"
        }
      },
      {
        hidden: true,
        path: "addRole",
        component: () => import("@/pages/account/role/addRole.vue"),
        name: "addRole",
        meta: {
          title: "新增角色",
          icon: "menu"
        }
      },
      {
        hidden: false,
        path: "accountInfo",
        component: () => import("@/pages/account/accountMng/info.vue"),
        name: "Account",
        id: 134,
        meta: {
          title: "账号信息",
          icon: "menu"
        }
      },
      {
        hidden: true,
        path: "addAccount",
        component: () => import("@/pages/account/accountMng/addAccount.vue"),
        name: "Account",
        meta: {
          title: "新增账号",
          icon: "menu"
        }
      }
    ]
  }
];

// 未登录之前的页面--登录、注册、用户须知、忘记密码
export const whiteRoutes = [
  {
    path: "/", //登录页面
    component: () => import("@/pages/login/index"),
    name: "index"
  },
  {
    path: "/login", //登录页面
    component: () => import("@/pages/login/index"),
    name: "login"
  },
  {
    path: "/blank", //登录中转页面
    component: () => import("@/pages/login/blank"),
    name: "blank"
  },
  {
    path: "/register", //注册页面
    component: () => import("@/pages/register/index"),
    name: "register"
  },
  {
    path: "/retrievePassword", //忘记页面
    component: () => import("@/pages/password/retrievePassword"),
    name: "retrievePassword"
  },
  {
    path: "/editPassword", //修改页面
    component: () => import("@/pages/password/editPassword"),
    name: "editPassword"
  },
  {
    path: "/seeNeeds", //用户须知
    component: () => import("@/pages/register/seeNeeds"),
    name: "seeNeeds"
  },
  {
    path: "/alertMsg", //弹框
    component: () => import("@/pages/bounced/alertMsg"),
    name: "alertMsg"
  },
  {
    path: "/alertrefused", //弹框
    component: () => import("@/pages/bounced/alertRefused"),
    name: "alertrefused"
  },
  {
    path: "/alertjump", //跳转到银链通的弹框
    component: () => import("@/pages/bounced/alertjump"),
    name: "alertjump"
  }
];

//路由配置赋值给常量constantRoutes
export const constantRoutes = [
  {
    path: "/index",
    name: 'Index',
    component: Index,
    children: [
      ...NavRouter,
      ...qiyeNavRouter
    ]
  },
  ...whiteRoutes
];

//路由初始化函数赋值给createRouter
const createRouter = () =>
  new Router({
    mode: "history",
    routes: constantRoutes
  });

//路由初始化
const router = createRouter();
export default router;

// 处理页签相关的逻辑
import tags from "@/components/tags";
const allValidRoutes = router.getRoutes();
const result = {};
let currentRoute;
function makeRouteNameMap(allRoutes) {
  for (const route of allRoutes) {
    if (route.id && (!route.hidden || route.meta.showTag)) {
      currentRoute = route;
    }
    const actualRoute = allValidRoutes.find(({ name }) => name === currentRoute.name)
    const { id, name, meta, path } = currentRoute;
    result[route.name] = {
      id, name, meta,
      path: actualRoute ? actualRoute.path : path
    };
    if (route.children) {
      makeRouteNameMap(route.children);
    }
  }
}
makeRouteNameMap([...qiyeNavRouter, ...NavRouter]);
router.beforeEach(async (to, from, next) => {
  try {
    if (to.name && result[to.name] && result[to.name].name === to.name) {
      // 点击侧边栏，就选择对应的页签
      tags.selectByRoute(result[to.name])
    } else if (from.name && result[from.name] && result[from.name].name === from.name) {
      // 从一个侧边栏所属的页面点进其他页面，则根据上一个页面的侧边栏来。比如说：点击采购订单中的补录订单，则页签根据采购订单（即from）来
      tags.selectByRoute(result[from.name]);
    } else {
      // 否则，页签根据当前页面所属的页面来
      tags.selectByRoute(result[to.name] || to);
    }
  } catch (error) {
    tags.selectByRoute(result[to.name] || to);
  }
  next();
});

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
