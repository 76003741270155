// https://danielkelly.io/blog/renderless-vue-dialog
import Vue from 'vue'
export class RareMessageBox {
  state = Vue.observable({
    type: 'alert',
    active: false,
    message: '',
    title: '提示',
    okText: '确定',
    cancelText: '取消',
    html: false
  })

  close
  open = (message) =>{
    this.state.message = message
    this.state.active = true
    return msgBoxPromise()
  }

  reset = () => {
    this.confirmCallback = undefined
    this.state.active = false
    this.state.message = ''
    this.state.okText = '确定'
    this.state.cancelText = '取消'
    this.state.html = false
    this.state.title = '提示'
    this.state.type = 'confirm'
  }

  /** 点击确认后要调用的函数，可以是一个Promise。 */
  confirmCallback
  get state() {
    return this.state
  }
  alert(message) {
    this.state.type = 'alert'
    return this.open(message)
  }
  confirm = (messageOrFunction) => {
    this.state.type = 'confirm'
    if (typeof messageOrFunction === 'function') {
      this.confirmCallback = messageOrFunction
      return this.open()
    }
    return this.open(messageOrFunction)
  }
  cancel = () => {
    close(false)
    this.reset()
  }
  ok = async () => {
    close(true)
    if (typeof this.confirmCallback === 'function') {
      await this.confirmCallback()
    }
    this.reset()
  }
  // 自定义属性，链式调用。
  title = (title) => {
    this.state.title = title
    return this
  }
  okText = (text) => {
    this.state.okText = text
    return this
  }
  cancelText = (text) => {
    this.state.cancelText = text
    return this
  }
  inputType = (type) => {
    this.state.inputType = type
    return this
  }
  html = (enabled = true) => {
    this.state.html = enabled
    return this
  }
}

//-----------------------------------
// Private Methods
//-----------------------------------
let close; // will hold our promise resolve function
const msgBoxPromise = () => new Promise((resolve) => close = resolve);

//-----------------------------------
// Public interface
//-----------------------------------

const msgBox = new RareMessageBox()

export default msgBox
Vue.prototype.$rareMsgBox = msgBox;
Vue.prototype.$rareConfirm = msgBox.confirm;
Vue.prototype.$rareAlert = msgBox.alert;

export const $rareMsgBox = msgBox
export const $rareConfirm = msgBox.confirm
export const $rareAlert = msgBox.alert