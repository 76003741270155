<!--
 * @Author: 祝瑶瑶 zhuyaoyao@youpinsanyue.com
 * @Date: 2022-09-26 11:09:02
 * @LastEditors: 祝瑶瑶 zhuyaoyao@youpinsanyue.com
 * @LastEditTime: 2022-09-26 17:46:17
 * @FilePath: \rare-admin\src\components\PageTit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="header_com"><i></i>{{ titName }}</div>
</template>
<script>
export default {
  props: {
    titName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.header_com {
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  line-height: 68px;
  border-bottom: 2px solid #e4e8f0;
  border-radius: 1px 1px;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #282d37;
  padding-left: 25px;
  background: #fff;
  font-size: 20px;
}
.header_com i {
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 19px;
  background-color: #2a4faa;
  border-radius: 2px;
  margin-right:10px;
}
.textalignCenter .header_com {
  text-align: left;
}
</style>
