<template>
  <el-dialog
    class="rare-message-box"
    width="400px" top="0" center
    :visible.sync="msgBox.state.active"
    :show-close="false"
  >
    <h2 slot="title" class="dialog-header">
      {{ msgBox.state.title }}
    </h2>
    <p v-if="msgBox.state.html" v-html="msgBox.state.message"></p>
    <p v-else>{{ msgBox.state.message }}</p>
    <template slot="footer">
      <button v-if="msgBox.state.type === 'confirm'" @click="msgBox.cancel">
        {{ msgBox.state.cancelText }}
      </button>
      <button @click="msgBox.ok">
        {{ msgBox.state.okText }}
      </button>
    </template>
  </el-dialog>
</template>

<script>
import msgBox from './messageBox';
export default {
  name: 'rare-message-box',
  data() {
    return { msgBox };
  }
}
</script>
