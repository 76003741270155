<template>
  <div class="wrap">
    <Header></Header>
    <div class="main">
      <side-nav />
      <div ref="view" @wheel="onWheelScroll" @scroll="onViewScroll" class="view" :class="{ 'scroll-to-bottom': scrollToBottom }">
        <div class="tags-view" ref="tagsView">
          <ul
            @wheel.prevent="scrollBarWheel"
            ref="ul"
            :style="{ width: ulWidth + 'px' }"
          >
            <li class="hand" v-for="({ on, title }, index) in tags.list"
              :class="{ on }"
              :key="index"
            >
              <p @click="tags.select(index)">
                <span>{{ title }}</span>
              </p>
              <span @click="tags.remove(index)" v-if="tags.list.length > 1"
                ><i class="el-icon-close"></i
              ></span>
            </li>
          </ul>
        </div>
        <router-view />
      </div>
    </div>
    <page-bottom />
  </div>
</template>

<script>
import Vue from 'vue';
import SideNav from "@/components/SideNav";
import Header from "@/components/Header";
import PageBottom from "@/components/PageBottom";
import tags from "@/components/tags";
/** 响应式的用户信息 */
const userInfo = Vue.observable({});
let isScrolling = false;
export default {
  name: "Index",
  components: {
    Header,
    PageBottom,
    SideNav
  },
  provide() {
    return {
      userInfo
    };
  },
  data() {
    return {
      tags,
      ulWidth: 1160, //默认设置1160px宽度，当子标签数量太多，会被函数撑大
      scrollToBottom: false
    };
  },
  watch: {
    '$route.path'() {
      this.$refs.view.scrollTop = 0;
      this.setUlWidth();
    }
  },
  methods: {
    //设置tag菜单宽度
    setUlWidth() {
      let num = 0;
      if (
        this.$refs.ul &&
        this.$refs.ul.children &&
        this.$refs.ul.children.length
      ) {
        num = Number(this.$refs.ul.children.length + 1);
      }
      let ulW = Number(num * 125);
      if (ulW > 1160) {
        this.ulWidth = ulW;
      } else {
        this.ulWidth = 1160;
      }
    },
    //鼠标滚动，移动水平滚动条，每次滚动125px,即一个标签
    scrollBarWheel(e) {
      if (e.deltaY > 0) {
        this.$refs.tagsView.scrollLeft += 125;
      } else {
        this.$refs.tagsView.scrollLeft -= 125;
      }
    },
    onWheelScroll(event) {
      const { deltaY } = event;
      if (deltaY < 0 && window.scrollY && !isScrolling) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        isScrolling = true;
        setTimeout(() => isScrolling = false, 200);
      }
    },
    onViewScroll({ target }) {
      this.scrollToBottom = Math.abs(target.scrollTop + target.clientHeight - target.scrollHeight) < 1 && target.scrollTop !== 0;
    }
  },
  beforeCreate() {
    Object.assign(userInfo, JSON.parse(sessionStorage.getItem("userinfo")));
  },
  mounted() {
    this.setUlWidth();
  }
};
</script>

<style>
.wrap {
  background-color: #f4f6fb;
}
.main {
  overflow: hidden;
  display: flex;
  height: calc(100vh - 70px);
  flex-direction: row;
}
.main > .view {
  margin: 0px auto;
  flex: 1;
  overflow: auto;
  li { cursor: pointer; }
}
.view.scroll-to-bottom .page-action {
  box-shadow: none;
}
.tags-view {
  width: 100%;
  height: 34px;
  margin-bottom: 16px;
  overflow: hidden;
  background-color: #ffffff;
}
.tags-view > ul {
  display: flex;
  justify-content: flex-start;
  width: auto;
}
.tags-view > ul > li {
  min-width: 121px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 0px;
  border: solid 1px #e3e3e3;
  display: flex;
  margin-right: 5px;
}
.tags-view > ul > li > p {
  flex: 1;
  display: flex;
  padding: 0px 14px;
  justify-content: flex-start;
  align-items: center;
}
.tags-view > ul > li > p > span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #9197a7;
}
.tags-view > ul > li.on > p > span {
  color: #2a4faa;
}
.tags-view > ul > li > span {
  width: 24px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tags-view > ul > li > span {
  color: #9197a7;
}
.tags-view > ul > li.on > span {
  color: #2a4faa;
}
</style>
