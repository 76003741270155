import request from "@/utils/request";
// 提交企业认证或变更信息
export function apiSubmitEnterpriseAuthInfo(data) {
  return request({
    url: "/rareEarth/enterpriseManage/submitEnterpriseAuthInfo",
    method: "post",
    data
  });
}
// 查询企业信息详情
export function apiQueryEnterpriseDetail(data) {
  return request({
    url: "/rareEarth/enterpriseManage/queryEnterpriseDetail?userId=" + data,
    method: "get"
  });
}

// 列表 /rareEarth/enterpriseManage/pageQueryEnterpriseList
export function apiQueryEnterpriseList(data) {
  return request({
    url: "/rareEarth/enterpriseManage/pageQueryEnterpriseList",
    method: "post",
    data
  });
}

// 审核或变更 /rareEarth/enterpriseManage/approveEnterpriseAuth
export function apiApproveEnterpriseAuth(data) {
  return request({
    url: "/rareEarth/enterpriseManage/approveEnterpriseAuth",
    method: "post",
    data
  });
}
// 操作记录 /rareEarth/enterpriseManage/queryLogList
export function apiQueryLogList(userId ) {
  return request({
    url: "/rareEarth/enterpriseManage/queryLogList?userId=" + userId,
    method: "get"
  });
}

// 停用启用 /rareEarth/userManage/enableUser
export function apiEnableUser(data) {
  return request({
    url: "/rareEarth/userManage/enableUser",
    method: "post",
    data
  });
}
// /rareEarth/userManage/pageQueryUserList   查询账号信息列表
export function apiPageQueryUserList(data) {
  return request({
    url: "/rareEarth/userManage/pageQueryUserList",
    method: "post",
    data
  });
}
//rareEarth/userManage/queryUserDetail
// 查询账号信息详情
export function apiQueryUserDetail(userId) {
  return request({
    url: "/rareEarth/userManage/queryUserDetail?userId=" + userId,
    method: "get"
  });
}

// 平台审核 /rareEarth/userManage/approveUserAuth
export function apiApproveUserAuth(data) {
  return request({
    url: "/rareEarth/userManage/approveUserAuth",
    method: "post",
    data
  });
}

// 下载委托书 /rareUser/downloadPowerAttorney
export function apiDownloadPowerAttorney() {
  return request({
    url: "/rareUser/downloadPowerAttorney",
    method: "post",
    responseType: "arraybuffer"
  });
}