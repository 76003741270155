import Vue from 'vue'
import { Route } from 'vue-router'
import router from '@/router/index'

/**
 * 页签的数据结构
 * @typedef {Object} 页签
 * @property {boolean} on 是否选中
 * @property {string} title 页签的标题
 * @property {Route} route 页签对应的路由对象
 */

/** @type {页签[]} */
const tags = Vue.observable([])

//-----------------------------------
// Private Methods
//-----------------------------------
/** @type {页签} 上次选中的页签 */
let lastSelectTag
function compareRoute(r1, r2) {
  return r1.path === r2.path
    && r1.name === r2.name
}
//-----------------------------------
// Public interface
//-----------------------------------
export default {
  get list() {
    return tags
  },
  get currentSelect() {
    return tags.find(({ on }) => on)
  },
  /**
   * 通过页签下标来选择页签，建议用在.vue单文件组件中。
   * @param {number|string} index 页签的下标
   */
  select(index) {
    const tagToSelect = tags[index]
    if (!tagToSelect) return
    for (const tag of tags) {
      if (tag.on) lastSelectTag = tag
      Vue.set(tag, 'on', tag === tagToSelect)
    }
    router.push(tagToSelect.route)
  },
  /**
   * 通过路由对象来选择页签，建议用在导航守卫中。
   * @param {Route} route 要选中的路由对象
   */
  selectByRoute(route) {
    if (!route || !route.meta.title) return
    for (const tag of tags) {
      if (tag.on) lastSelectTag = tag
      Vue.set(tag, 'on', compareRoute(tag.route, route))
    }
    const routeIndex = tags.findIndex(tag => compareRoute(tag.route, route))
    if (routeIndex === -1)
      tags.push({ on: true, route, title: route.meta.title })
  },
  /**
   * 通过页签下标来删除页签，建议用在.vue单文件组件中。
   * @param {number|string} index 页签的下标
   */
  remove(index) {
    if (tags.length <= 1) return
    const tagToRemove = tags[index]
    if (!tagToRemove) return
    tags.splice(index, 1)
    if (tagToRemove.on) {
      for (const tag of tags) {
        Vue.set(tag, 'on', tag === lastSelectTag)
      }
      router.go(-1)
    } else {
      // 无用的代码，只是为了让Vue有响应式，不知道为什么splice没用。
      const selected = tags.find(({ on }) => on)
      for (const tag of tags) {
        Vue.set(tag, 'on', false)
        Vue.set(tag, 'on', tag === selected)
      }
    }
  }
}