// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import ElementUI from "element-ui";
import '@/assets/css/variables.scss';
import "element-ui/lib/theme-chalk/index.css";

// import "babel-polyfill";
import App from "./App";
import router from "./router";
import "../src/assets/css/purchase.scss";
import '@/assets/css/rare.scss'
import '@/assets/css/element.scss'
import preventReClick from'@/utils/preventRepeatClick.js'
Vue.use(preventReClick)

//引入全局组件
import PageTit from "@/components/PageTit";
import FileLink from '@/components/FileLink.vue'
Vue.component("PageTit", PageTit);
Vue.component("FileLink", FileLink);

Vue.use(ElementUI);
import { Message } from "element-ui";
Vue.config.productionTip = false;
Vue.prototype.$message = Message
/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  components: { App },
  template: "<App/>"
});
