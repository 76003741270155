<template>
  <div class="authen">
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="432px"
      center
      :show-close="false"
      @close="close"
    >
      <div class="fileCon">
        <div class="close" @click="close(1)"></div>
        <div class="content">
          还未完成企业认证，请完成企业认证后<br />
          方可开展业务
        </div>
        <div class="btn" @click="close(2)">去认证</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isInformation: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isInformation: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.centerDialogVisible = this.isInformation;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      centerDialogVisible: false
    };
  },
  methods: {
    close(val) {
      this.centerDialogVisible = false;
      if (val == 2) {
        this.$emit("closeDialog", this.centerDialogVisible);
      } else {
        this.$emit("closeDialog", 1);
      }
    }
  }
};
</script>

<style lang="scss">
.authen .el-dialog__wrapper .el-dialog {
  border-radius: 8px !important;
  .el-dialog__body {
    padding: 16px 61px 32px !important;
  }
  .el-dialog__title {
    font-size: 18px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #222222;
  }
  .fileCon {
    position: relative;
    .close {
      width: 24px;
      height: 24px;
      background: url("@/assets/img/images/close.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -44px;
      top: -33px;
      cursor: pointer;
    }
    .content {
      font-size: 18px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #282d37;
      line-height: 30px;
    }
    .btn {
      width: 100px;
      height: 34px;
      background: #2a4faa;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 34px;
      display: block;
      margin: 30px auto 0;
      cursor: pointer;
    }
  }
}
</style>
