import Vue from 'vue'

const msg = Vue.observable({
  show: false,
  content: '',
  time: 1500,
  html: false
})

let close
let timeout
const msgAlertPromise = (time) => new Promise((resolve) => {
  close = resolve
  timeout = setTimeout(() => {
    msg.show = false
    resolve()
  }, time)
})
const msgAlert = {
  get show() {
    return msg.show
  },
  get html() {
    return msg.html
  },
  get content() {
    return msg.content
  },
  alert(message, time = 1500, html = false) {
    msg.content = message
    msg.time = time
    msg.html = html
    msg.show = true
    return msgAlertPromise(time)
  },
  close() {
    msg.show = false
    clearTimeout(timeout)
    close()
  }
}

Vue.prototype.$alertMsg = msgAlert.alert

export default msgAlert

export const $alertMsg = msgAlert.alert