import Vue from 'vue'

const state = Vue.observable({
  file: {},
  show: false
})

const imageDialog = {
  get show() {
    return state.show
  },
  get url() {
    return state.file.fileUrl || state.file.url || ''
  },
  previewFile(fileOrUrl) {
    if (typeof fileOrUrl === 'string') {
      state.file = { fileUrl: fileOrUrl }
      } else {
      state.file = fileOrUrl
    }
    const url = state.file.fileUrl || state.file.url
    if (url) {
      const ext = url.substring(url.lastIndexOf('.'))
      if (['.jpeg', '.jpg', '.png', '.svg', '.gif', '.webp'].includes(ext)) {
        state.show = true
      } else {
        window.open(url)
      }
    }
  },
  close() {
    state.show = false
  }
}

Vue.prototype.$previewFile = imageDialog.previewFile

export default imageDialog

export const $previewFile = imageDialog.previewFile