import request from "@/utils/request";

// demo
export function getImgCode() {
  //获取验证码
  return request({
    url: "/permission/getImgCode",
    method: "get"
  });
}
export function sendSms(telephone, register) {
  //获取手机短信验证码,z注册的时候register传true,其他时候传false；农产品systemId固定穿4
  return request({
    url: `/permission/sendSms?telephone=${telephone}&systemId=13&register=${register}`,
    method: "get"
  });
}

export function saveEnterpriseAndUser(data) {
  //注册
  return request({
    url: "/user/saveEnterpriseAndUser",
    method: "post",
    data
  });
}
export function accountLogin(account, password, sySystemId) {
  //登录
  return request({
    url: `/permission/accountLogin?account=${account}&password=${password}&sySystemId=${sySystemId}`,
    method: "post"
  });
}
export function logout(sessionId) {
  //退出登录
  return request({
    url: `/permission/logout?sessionId=${sessionId}`,
    method: "get"
  });
}
export function passwordUpdate(id, newPwd, password) {
  //修改密码
  return request({
    url: `/enterprise/passwordUpdate?id=${id}&newPwd=${newPwd}&password=${password}`,
    method: "get"
  });
}
export function userUpdate(data) {
  //忘记密码
  return request({
    url: `/user/update`,
    method: "post",
    data
  });
}
export function getUserByAccountOrTelephone(data) {
  //检查用户名称跟手机号是否匹配
  return request({
    url: `/user/getUserByAccountOrTelephone`,
    method: "post",
    data
  });
}
export function verifyCode(code, telephone) {
  //校验验证码
  return request({
    url: `/permission/verifyCode?code=${code}&telephone=${telephone}`,
    method: "get"
  });
}

// 登录 --sessionId
export function sessionLogin(id) {
  return request({
    url: `/permission/getUserInfo?sessionId=${id}`,
    method: "get"
  });
}

// /dealer/finance  发起融资  
export function apiDealerFinance(data) {
  return request({
    url: `/dealer/finance`,
    method: "post",
    data
  });
}

// 退出登录
export function apiLogout(id) {
  return request({
    url: `/rareLogin/logout?sessionId=${id}`,
    method: "get"
  });
}

// 
// 用户名密码登录
export function apiAccountLogin(data) {
  //注册
  return request({
    url: "/rareLogin/accountLogin",
    method: "post",
    data
  });
}