const possibleTargetOriginList = [
  'http://localhost:8080',
  'https://test-rare-scm.mxscm.cn',
  'https://uat-rare-scm.mxscm.cn',
  'https://www.mxscm.cn',
  'https://www.mengxiscm.com'
]

export function postLogoutMessage(identity, expired) {
  if (window.opener) {
    const eventName = expired ? 'logout:expired' : 'logout'
    possibleTargetOriginList.forEach(origin => window.opener.postMessage(eventName, origin))
    if (identity != 4) window.close()
  }
}
