<template>
  <div class="header">
    <div>
      <div class="logo" @click="toFront">
        <img src="@/assets/img/login/logo.png" />
        <span class="logo-word">稀土产业数字供应链科技服务平台</span>
      </div>
      <div class="login">
        <p>
          <span @click="toFront">{{ name }}</span>
          <span>|</span>
          <span @click="exitLogin()">退出登录</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { apiLogout } from "@/api/login";
import { postLogoutMessage } from '@/utils/logout'
export default {
  name: "Header",
  data() {
    return {
      name: "",
      userinfo: {}
    };
  },
  created() {
    if (sessionStorage.getItem("userinfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
      // 手机号中间四位显示为****
      var phone =
        this.userInfo.telephone.substring(0, 3) +
        "****" +
        this.userInfo.telephone.substring(7);
      let name2 = this.userInfo.account;
      if (JSON.parse(this.userInfo.identity) == 4) {
        this.name = name2;
      } else {
        this.name = phone;
      }
    } else {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    modifyPW() {
      this.$router.push({
        path: "/editPassword"
      });
    },
    exitLogin() {
      const data = {
        id: JSON.parse(sessionStorage.getItem("userinfo")).id,
        sessionId: JSON.parse(sessionStorage.getItem("userinfo")).sessionId
      };
      apiLogout(data.id).then(() => {
        this.$message.success("退出成功");
        localStorage.clear();
        sessionStorage.clear();
        postLogoutMessage(this.userInfo.identity)
        this.$router.push({ path: "/login" });
      });
    },
    toFront() {
      let url = process.env.BASE_URL;
      window.location.href = url;
    }
  }
};
</script>

<style scoped>
.header {
  margin: 0px auto;
  height: 70px;
  background-color: #2a4faa;
  padding: 0 42px;
}
.header > div {
  margin: 0px auto;
  height: 70px;
  display: flex;
  align-items: center;
}
.logo {
  flex: 1;
  display: flex;
  align-items: center;
}
.logo img {
  cursor: pointer;
}
.logo .logo-word {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}
.login {
  width: 400px;
  height: 90px;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 18px;
  color: #fff;
}

.login p span {
  cursor: pointer;
}
.logo img {
  vertical-align: middle;
  margin-right: 11px;
}
.logo .logo_nav {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 53px;
  letter-spacing: 0px;
  color: #666666;
}
</style>
