<template>
  <div class="sidenav">
    <el-menu
      background-color="#ffffff"
      text-color="#666666"
      active-text-color="#ffffff"
      mode="vertical"
      style="height:100%"
      :default-active="activeIndex"
    >
      <template v-for="(route, key) in nativeRoutes">
        <el-submenu
          v-if="route && !route.hidden"
          :index="'' + key"
          :key="route.path"
          :base-path="route.path"
        >
          <template slot="title">
            <i class="navicon" :class="`${route.meta.icon}`"></i>
            <span>{{ route.meta.title }} </span>
          </template>
          <template v-for="(subroute, subkey) in route.child">
            <el-menu-item
              v-if="subroute && !subroute.hidden"
              :key="'' + key + '-' + subkey"
              :index="'' + subroute.path"
              @click="bounced(subroute)"
            >
              {{ subroute.meta.title }}
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
    <AuthenticationDialog
      :isInformation="isAuthentication"
      @closeDialog="closeDialog"
    ></AuthenticationDialog>
  </div>
</template>

<script>
import AuthenticationDialog from "@/components/authenticationDialog.vue";
import { NavRouter, qiyeNavRouter } from "@/router";
import { apiQueryEnterpriseDetail } from "@/api/newenterprise.js";
import tags from "./tags";

export default {
  components: {
    AuthenticationDialog
  },
  name: "SideNav",
  data() {
    return {
      isAuthentication: false,
      nativeRoutes: [], //企业
      isHidden: "",
      userinfo: {},
      status: null,
      showStatus: null,
      sessionId: 1
    };
  },
  computed: {
    activeIndex() {
      return tags.currentSelect ? tags.currentSelect.route.path : '';
    }
  },
  methods: {
    closeDialog(val) {
      if (val == false) {
        if (this.isAuthentication) {
          this.isAuthentication = false;
          this.status = sessionStorage.getItem("authStauts");
          this.toEnterpriseCertification(this.status);
        }
      } else {
        this.isAuthentication = false;
      }
    },
    toEnterpriseCertification(status) {
      this.$router.push({
        path: "/home/enterpriseCertification",
        query: { status }
      });
    },
    getInfo(subroute) {
      let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
      apiQueryEnterpriseDetail(userinfo.id).then(res => {
        if (res.code == 200) {
          this.showStatus = res.data.userInfo.userStatus;
          sessionStorage.setItem("authStauts", this.showStatus);
          if (this.showStatus == 3 || this.showStatus == 4) {
            sessionStorage.setItem("showToauth", null);
          } else {
            sessionStorage.setItem("showToauth", 1);
          }

          this.sessionId = sessionStorage.getItem("showToauth");
          if (this.sessionId && this.sessionId == 1) {
            this.isAuthentication = true;
          } else {
            this.$router.push({
              path: subroute.path
            });
          }
        }
      });
    },
    bounced(subroute) {
      if (
        JSON.parse(this.userinfo.identity) != 4 &&
        subroute.meta.title != "首页"
      ) {
        this.getInfo(subroute);
      } else {
        this.$router.push({
          path: subroute.path
        });
      }
    },
    traverseRoutes(native, remote) {
      let arr = [];
      for (const route of native) {
        if (remote) {
          // native 全部路由  remote选择的路由
          const res = remote.find(r => r.id === route.id);
          route.hidden = res ? false : true;
          if (res && !res.hidden && route.children && route.children.length) {
            this.traverseRoutes(route.children, res.child);
          }
          arr.push(res);
        }
      }
      this.nativeRoutes = arr;
    }
  },
  created() {
    if (sessionStorage.getItem("userinfo")) {
      this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
      let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
      let resourceJson = JSON.parse(userinfo.roleList[0].resourceJson);
      let type = JSON.parse(userinfo.identity);
      this.nativeRoutes = [];
      if (type == 4) {
        this.nativeRoutes = qiyeNavRouter; //平台
      } else {
        this.nativeRoutes = NavRouter; // 借款客户
      }
      this.traverseRoutes(this.nativeRoutes, resourceJson);
    }
  }
};
</script>

<style>
/* 全局样式控制菜单
   不可以设置成局部
 */
/*第一层菜单font-size 18px */
.sidenav > ul > li > div > span {
  font-size: 18px;
  color: #282d37;
}
.sidenav > ul > li.is-active > div > span {
  color: #2a4faa;
}
.sidenav > ul > li.is-active > div > i {
  color: #2a4faa;
}

.sidenav > ul > li > ul > li.is-active {
  border-right: 4px solid #2a4faa;
  color: #2a4faa !important;
  background-color: #eef1f9 !important;
}

.sidenav > ul.el-menu > li > ul > li {
  padding-left: 50px !important;
  font-size: 16px;
}

/* icon */
.navicon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
}
.navicon.enterprise {
  background-image: url("/public/static/img/icon_nav/enterprise.png");
}
.is-active .navicon.enterprise {
  background-image: url("/public/static/img/icon_nav/enterprise_on.png");
}
.navicon.supplier {
  background-image: url("/public/static/img/icon_nav/supplier.png");
}
.is-active .navicon.supplier {
  background-image: url("/public/static/img/icon_nav/supplier_on.png");
}
.navicon.constract {
  background-image: url("/public/static/img/icon_nav/constract.png");
}
.is-active .navicon.constract {
  background-image: url("/public/static/img/icon_nav/constract_on.png");
}
.navicon.invoice {
  background-image: url("/public/static/img/icon_nav/invoice.png");
}
.is-active .navicon.invoice {
  background-image: url("/public/static/img/icon_nav/invoice_on.png");
}
.navicon.file {
  background-image: url("/public/static/img/icon_nav/file.png");
}
.is-active .navicon.file {
  background-image: url("/public/static/img/icon_nav/file_on.png");
}
.navicon.financing {
  background-image: url("/public/static/img/icon_nav/financing.png");
}
.is-active .navicon.financing {
  background-image: url("/public/static/img/icon_nav/financing_on.png");
}
.navicon.manage {
  background-image: url("/public/static/img/icon_nav/manage.png");
}
.is-active .navicon.manage {
  background-image: url("/public/static/img/icon_nav/manage_on.png");
}
.navicon.match {
  background-image: url("/public/static/img/icon_nav/match.png");
}
.is-active .navicon.match {
  background-image: url("/public/static/img/icon_nav/match_on.png");
}
.navicon.news {
  background-image: url("/public/static/img/icon_nav/news.png");
}
.is-active .navicon.news {
  background-image: url("/public/static/img/icon_nav/news_on.png");
}
.navicon.xitu {
  background-image: url("/public/static/img/icon_nav/xitu.png");
}
.is-active .navicon.xitu {
  background-image: url("/public/static/img/icon_nav/xitu_on.png");
}
.navicon.account {
  background-image: url("/public/static/img/icon_nav/account.png");
}
.is-active .navicon.account {
  background-image: url("/public/static/img/icon_nav/account_on.png");
}
</style>

<style scoped>
.sidenav {
  /* margin-top: 20px; */
  overflow: auto;
  min-width: 180px;
  width: 11em;
  height: auto;
  user-select: none;
}
</style>
